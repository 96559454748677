export default {
  // ? =============================
  // ? =======  reuseables  ========
  // ? =============================

  title: {
    fontSize: ['2rem', '', '', '2.5rem'],
    order: '2',
    textTransform: 'uppercase',
    padding: '1rem 0rem',
    width: 'fit-content'
  },
  subtitle: {
    fontWeight: '300',
    opacity: '0.8',
    margin: '0rem 0rem 1.5rem',
    order: '1',
    color: 'primary'
  },

  text: {
    order: '3',
    fontSize: '0.9rem',
    lineHeight: '2',
    opacity: '0.8',
    em: {
      fontFamily: 'handwriting',
      fontSize: '2em',
      letterSpacing: '2px'
    }
  },
  ctaWidget: {
    a: {
      padding: '1.25rem .75rem',
      fontSize: '1rem'
    }
  },
  ctaButton: {
    order: '4',
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  brownBackground: {
    content: "''",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(118,94,79,0.25)'
  },

  // ? ==========================
  // ? =======  Layout  =========
  // ? ==========================

  navigation: {
    '> .container': {
      padding: ['0.5rem', '0.5rem', '0.5rem', '4.5rem 1rem']
    },
    '.containerScrolled': {
      height: '100px',
      backgroundColor: 'primaryLight',
      '.smallNavMenu': {
        '.navItemDropdownMenu': {
          backgroundColor: 'primaryLight'
        }
      },
      '.logoLocationContainer': {
        // display: 'none',
        margin: ['', '', '', 'auto auto auto 1rem'],
        position: ['', '', '', 'static'],
        padding: '0rem',
        '.logoScrolled ': {
          marginBottom: '0rem',
          maxHeight: '70px',
          padding: '0rem',
          a: {
            display: 'flex'
          },
          img: {
            // maxWidth: '80px',
            maxHeight: '70px',
            height: 'unset',
            padding: '0rem'
          }
        },
        '.locationSwitcher': {
          justifyContent: 'center',
          button: {
            fontSize: '0.8rem'
          }
        },
        '.popUp': {
          transform: 'unset'
        }
      }
    },
    '.logoLocationContainer': {
      // display: 'none',
      margin: ['', '', '', '0rem auto 0rem 0rem'],
      position: ['', '', '', 'static'],
      padding: ['0rem', '0rem', '0rem', '0rem'],
      '.container': {
        // padding: ['0.5rem'],
      },
      '.logo': {
        maxWidth: '125px',
        marginBottom: '0.5rem',
        img: {
          filter: 'unset'
        }
      },
      '.locationSwitcher': {
        justifyContent: 'center',
        button: {
          fontSize: '0.8rem'
        }
      },
      '.popUp': {
        transform: 'unset'
      }
    },

    '.smallNavMenu': {
      '.react-reveal': { display: 'flex', flexDirection: 'row' },
      '.navItemDropdownMenu': {
        backgroundColor: 'transparent',
        width: 'fit-content'
      },
      '.navItem': {
        color: 'white',
        margin: ['', '', '', '0.5rem', '0.75rem'],
        a: {
          fontSize: ['0.75rem', '0.75rem', '0.7rem', '0.68rem', '0.8rem', '1rem'],
          ':hover': {
            color: 'lightgrey',
            textDecoration: 'underline'
          }
        }
      },
      '.reservations': {
        // margin: '0rem',
        a: {
          padding: '1rem',
          border: 'solid 1px',
          borderColor: 'primary'
        }
      }
    },
    '.hamburger': {
      backgroundColor: 'rgb(0 0 0 / 42%)',
      marginRight: '0rem',
      height: '30px',
      width: '30px',
      padding: '0.25rem',
      div: {
        backgroundColor: 'white',
        height: '2px'
      }
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundColor: 'backgroundSecondary',
      width: ['85%', '60%', '50%', '40%'],
      color: 'light',
      '.navItemDropdown': {
        width: '100%'
      },
      '.navItemDropdownMenu': {
        position: 'static',
        background: 'transparent',
        '.navItem': {
          width: ['', '', 'fit-content'],
          a: {
            fontSize: '1rem'
          }
        }
      },
      '.navItem': {
        textAlign: 'right',
        width: ['', '', '100%'],
        margin: '0.5rem 0rem',
        a: {
          fontSize: '1.2rem'
        }
      },
      '.navMenuLogo': {
        position: ['', '', '', ''],
        width: ['', '', '', '100%'],
        maxWidth: '200px',
        left: '1rem',
        margin: '0rem 0.5rem 2rem',
        img: {
          filter: 'brightness(0) invert(1)',
          maxHeight: ['', '', '', 'unset'],
          maxWidth: ['', '', '', 'unset'],
          width: ['', '', '', '100%']
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          svg: {
            display: 'none'
          }
        },
        '.facebook': {
          position: 'relative',
          width: '50px',
          height: '50px',
          '::after': {
            content: "''",
            position: 'absolute',
            bottom: '0',
            background:
              'url(https://res.cloudinary.com/gonation/image/upload/v1687447451/sites/The%20Original%20Vazzy%27s/facebook-removebg-preview.png)',
            backgroundSize: 'cover',
            height: '40px',
            width: '40px',
            backgroundColor: 'white',
            borderRadius: '50%'
          }
        },
        '.instagram': {
          position: 'relative',
          width: '50px',
          height: '50px',
          '::after': {
            content: "''",
            position: 'absolute',
            bottom: '0',
            background:
              'url(https://res.cloudinary.com/gonation/image/upload/v1687447212/sites/The%20Original%20Vazzy%27s/instagram-logo.png)',
            backgroundSize: 'cover',
            height: '40px',
            width: '40px',
            backgroundColor: 'white',
            borderRadius: '50%'
          }
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right']
      },

      '.socialIconsContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        svg: {
          filter: 'brightness(0) invert(1)'
        },
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      }
    },

    '.navBlockOpen': {
      width: ['15%', '40%', '50%', '60%'],
      left: '0rem',
      backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/v1675711026/sites/esh/nav-bg.jpg)',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }
  },

  footer: {
    backgroundColor: 'primaryLight',
    color: 'light',
    '.columnSocial': {
      display: 'none'
    },
    '.gonationLogo': {
      svg: {
        fill: 'light'
      }
    }
  },

  sideBySide1: {
    minHeight: ['', '', 'calc(75vh - 77px)'],
    '.content': {
      padding: ['1rem', '', '', '4rem', '5rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      variant: 'customVariants.ctaButton'
    }
  },

  // ? =======================
  // ? =====  Homepage  ======
  // ? =======================

  homepageHero: {
    position: 'relative',
    '.hero_content_container': {
      top: 'unset',
      right: 'unset',
      bottom: '0rem',
      left: '0rem',
      transform: 'unset',
      '::before': {
        content: "''",
        position: 'absolute',
        bottom: '2rem',
        left: '2rem',
        backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/v1678388279/best-of-ct.png)',
        height: '130px',
        width: '130px',
        backgroundSize: '100% 100%'
      },
      margin: 'auto',
      padding: '2rem',
      zIndex: '10'
    },
    '.logoHero': {
      maxHeight: '180px'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'light'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },

    '.slick-slider': {
      img: {
        filter: 'brightness(0.9)'
      },
      '::before': {
        variant: 'customVariants.brownBackground'
      }
    }
  },

  homepageHeroShout: {
    display: 'none!important'
  },
  homepageShout: {
    backgroundColor: 'background',
    padding: ['0rem', '', '', '0rem'],
    marginTop: ['1rem', '', '', '0rem'],
    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      backgroundColor: 'secondary',
      fontFamily: 'body'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['primaryLight', '', '', 'primaryLight'],
      color: '#e5d5c1'
    },
    '.date': {
      backgroundColor: 'backgroundSecondary',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer'
    },
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    position: 'relative',
    '.lazyload-wrapper': {
      position: 'relative',
      '::after': {
        content: "''",
        zIndex: '10',
        position: 'absolute',
        top: '0rem',
        left: '0rem',
        height: ['200px', '', '', '300px'],
        width: ['200px', '', '', '300px'],
        backgroundImage:
          'url(https://res.cloudinary.com/gonation/image/upload/v1687373926/sites/The%20Original%20Vazzy%27s/Vazzy_s_30th_Anniversary_Logo_FINAL__1_-removebg-preview.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        borderRadius: '50%'
      }
    }
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1',
    color: 'white',
    '::before': {
      variant: 'customVariants.brownBackground'
    },
    '.section': {
      zIndex: '1',
      '.subtitle': {
        color: '#dbd3c9',
        fontSize: ['1.75rem', '2rem'],
        borderBottom: '2px solid'
      },
      '.text': {
        '*': {
          color: 'light'
        }
      }
    }
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1',
    padding: '3rem',
    height: 'calc(60vh + 6rem)',
    minHeight: 'unset',
    background: 'none',
    '.slick-initialized .slick-slide > div': {
      height: '60vh'
    },
    '.slick-slide img': {
      height: '60vh'
    },
    '.slick-arrow': {
      display: 'none!important'
    }
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide1',
    color: 'white'
  },

  homepageContact: {
    variant: 'customVariants.sideBySide1',
    // color: 'white',
    '.section': {
      backgroundColor: 'background',
      p: 3,
      height: '100%',
      margin: 'auto'
    }
  },

  //  ? ======================
  //  ? ====  About page  ====
  //  ? ======================

  aboutSection: {
    '.title': {
      fontSize: '3.5rem',
      lineHeight: '1',
      color: 'primary'
    }
  },

  aboutPageSlider: {
    padding: '0rem',
    '.slick-dots': {
      display: 'none !important'
    }
  },

  //  ? ========================
  //  ? ======  Menu page  =====
  //  ? ========================

  menu: {
    // border: 'solid 1px black',
    padding: ['1rem', '1rem', '1rem', '1rem'],
    margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    width: ['calc(100% - 1rem)'],

    '#sec-rM0_hhC5R9WVXc89eYGS9Q, #sec-LQCzd2Z2QhmuNGMX6VOERA, #sec-QO7gbqG0Tge62EoKsI7Dzg, #sec-VFYrcfF-SzaKNyKHGBw6Fg, #sec-JbMnvOpcQNqFZaq17hYrZA':
      {
        '.menuItemName': {
          width: 'calc(100% - 3rem)'
        },
        '.itemVariantsContainer': {
          '.variantsContainer': {
            '.variantContainerFloatTopRight': {
              right: '2rem'
            },
            '.variantContainer': {
              '::before': {
                mr: '0.25rem',
                content: "'/'"
              },
              position: 'absolute',
              top: '0%',
              right: '0%'
            }
          }
        }
      },

    '.backToMenuBtn': {
      mb: '2rem'
    },
    backgroundColor: 'transparent',
    '.cellContainer': {
      justifyContent: 'flex-start'
    },
    '.section-cell': {
      position: 'relative',
      color: 'white',
      borderRadius: '0px',
      width: ['100%', '', 'calc(50% - 1rem)', 'calc(33.33% - 1rem)']
    },
    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.tabsRow': {
      marginBottom: '2rem'
    },
    '.dropdownContainer': {
      display: 'none'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },
    '.menuItemInnerContainer, .menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuSectionTitle': {
      margin: '1rem auto 1rem',
      borderBottom: '2px solid black',
      padding: '0 1rem 1rem',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '2rem'],
      textAlign: 'center',

      color: 'black'
    },
    '.menuSectionDescription': {
      padding: '1rem 1rem 1.5rem',
      fontSize: '1.1rem',
      maxWidth: '800px',
      textAlign: 'center',
      margin: 'auto'
    },

    '.menuCell': {
      border: 'none'
    },
    '.cellName': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      fontFamily: 'handwriting',
      textTransform: 'lowercase',
      fontSize: '3.5rem',
      width: '90%',
      textAlign: 'center',
      margin: '0rem'
    },
    '.cellImage': {
      filter: 'brightness(0.6)',
      borderRadius: '0px'
    },

    '.menuItemContainer': {
      padding: '0.5rem'
      // margin: '0.5rem',
    },
    '.menuItemName': {
      textTransform: 'uppercase',
      fontFamily: 'heading',
      letterSpacing: '2px',
      fontSize: '1.25rem'
    },
    '.menuPriceContainer': {
      minWidth: '50px',
      color: 'black'
    }
  },

  menuCTAS: {
    '.ctaLink': {
      variant: 'buttons.primary',
      borderRadius: '0px',
      margin: '0 auto'
    }
  },

  //  ? ========================
  //  ? ====  Gallery page  ====
  //  ? ========================

  gallery: {
    // '.albumsContainer': {
    //   display: 'none',
    // },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  //  ? ========================
  //  ? ====  Events page  ====
  //  ? ========================

  events: {
    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    },
    '.logo': {
      filter: 'brightness(0)'
    }
  },

  //  ? ========================
  //  ? ====  Contact page  ====
  //  ? ========================

  contactForm: {
    padding: '6rem 1rem',
    order: '4',
    backgroundColor: '#f0f0ee',
    '.title': {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      padding: '0.5rem',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1.25rem',
      opacity: '0.7',
      fontWeight: '200',
      color: 'black',
      marginBottom: '2rem'
    },
    '.text': {},
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
        margin: '1.5rem 0rem'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
        margin: '1.5rem 0rem'
      },
      '.submitBtn': {
        borderRadius: '0px'
      }
    }
  },
  locationMap: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    '::before': {
      content: "''",
      paddingTop: '165px',
      width: '100%',
      backgroundColor: 'primaryLight'
    },
    order: '3',
    backgroundColor: '#e4e4e4',
    '.content_container': {
      padding: '4rem 1rem'
    },
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      padding: '0.5rem'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },
  //  ? =============================
  //  ? =====  Gift Cards Page  =====
  //  ? =============================

  giftcardSection: {
    background: 'none',
    zIndex: '10',
    margin: '0rem auto',
    paddingTop: '0rem',
    '.title': {
      position: 'relative',
      textTransform: 'uppercase',
      color: 'primary',
      top: '-5rem',
      fontSize: ['3rem', '4rem', '6rem', '7rem'],
      textShadow: '2px 2px 0px black'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  //  ? =============================
  //  ? ====  Private Room page  ====
  //  ? =============================

  privateroom: {
    width: '100%',
    maxWidth: 'unset',
    backgroundColor: 'transparent',
    padding: ['0rem', '0rem', '0rem', '0rem'],
    borderRadius: '0px',
    marginBottom: '0rem',
    '.innerMenuContainer': {
      padding: '3rem 1rem'
    },
    '.allInContainerWrapper': {
      backgroundColor: 'transparent'
    },
    '.menuSectionTitle': {
      display: 'none'
    },

    '.menuSectionDescription': {
      maxWidth: '767px',
      padding: '0rem 2rem',
      lineHeight: '1.75',
      fontSize: '1.25rem'
    },

    '.menuItemContainer, .menuItemContainerImgActive': {
      width: ['100%', '', 'calc(50% - 2rem)', 'calc(50% - 2rem)', 'calc(50% - 2rem)'],
      margin: ['', '', '1rem'],
      border: 'solid 1px black'
    },

    '.menuItemImageContainer': {
      height: '400px',
      width: '100%'
    },
    '.menuItemContentContainer ': {
      height: 'auto',
      padding: ['1rem', '2rem', '1rem', '2rem'],
      flexGrow: '1',
      alignItems: 'center'
      // justifyContent: 'center',
    },

    '.menuItemNamePriceContainer': {
      width: '100%'
    },

    '.menuItemName': {
      marginBottom: '2rem',
      width: '100%',
      fontSize: ['1.5rem', '', '2rem', '2.5rem'],
      borderBottom: '1px solid',
      paddingBottom: '0.5rem'
    },
    '.menuItemDescription': {
      width: '100%'
    },
    '.menuItemImgDefault, .menuItemImgDefaultContainer': {
      display: 'none'
    }
  },
  cateringPrivateParties: {
    '.section': {
      backgroundColor: 'primary',
      color: 'white',
      padding: '2rem 1rem',
      '.title': {
        color: 'white',
        order: '1'
      },
      '.subtitle': {
        color: 'white',
        order: '2'
      },
      '.text': {
        order: '4',
        py: '4',
        color: 'inherit',
        li: {
          textAlign: 'left'
        }
      },
      '.linksContainer': {
        order: '3'
      },
      '.ctaButton': {
        backgroundColor: 'backgroundSecondary',
        borderColor: 'backgroundSecondary',
        ':hover': {
          opacity: '0.7'
        }
      },
      '.secondaryCtaButton': {
        variant: 'buttons.primary',
        backgroundColor: 'backgroundSecondary',
        borderColor: 'backgroundSecondary',
        ':hover': {
          opacity: '0.7'
        }
      }
    }
  }
}
